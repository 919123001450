import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { CitiesSearch, CountriesSearch } from '../../../location'
import { FormikTextInput, Icon, RoundedButton, TooltipWrapper } from '../../../shared'
import { City, Country } from '../../../../model/Contact'
import { DisabledINSITooltipWrapper } from '../DisabledINSITootipWrapper'
import { PatientFormFields } from '../PatientForm.model'
import { PatientBirthPlaceCodeBlockProps } from './PatientBirthPlaceCodeBlock.model'
import { CitySourceFilter } from '../../../../store/ui/cities'
import { useResetINSIStatusHook } from '../useResetINSIStatus.hook'
import { inseeNumberValidator } from '../../../../misc/inseeNumber.utils'
import { isINSIValidated } from '../../../../misc/patient.utilities'
import style from './PatientBirthPlaceCodeBlock.module.scss'

export const PatientBirthPlaceCodeBlock: FC<PatientBirthPlaceCodeBlockProps> = ({
  cities,
  countries,
  selectedCountry,
  patient,
  disabled,
  searchCity,
  searchCountry,
  setSelectedCountry,
}) => {
  const [countrySearch, setCountrySearch] = useState('')

  const { values, setValues } = useFormikContext<PatientFormFields>()

  const [citySearch, setCitySearch] = useState(patient?.birthPlaceCity ?? '')

  const [searchBirthPlaceCodewithFilter, setSearchBirthPlaceCodeWithFilter] = useState(false)

  const resetINSIStatus = useResetINSIStatusHook()

  const isDefaultBirthPlaceCode = useMemo(
    () => values.birthPlaceCode === '99999',
    [values.birthPlaceCode],
  )

  const isDisabled = disabled || inseeNumberValidator(values.inseeNumber)

  useEffect(() => {
    if (patient?.birthPlaceCountry) {
      setCountrySearch(patient.birthPlaceCountry)
    } else if (patient?.birthPlaceCode === undefined) {
      setCountrySearch('FRANCE')
    }
  }, [patient])

  const handleSearchCountry = useCallback(() => {
    searchCountry({
      page: { currentPage: 1, pageSize: 10 },
      filters: { search: countrySearch },
    })
  }, [countrySearch, searchCountry])

  const handleSearchCity = useCallback(() => {
    searchCity({
      page: { currentPage: 1, pageSize: 10 },
      filters: {
        search: citySearch,
        country: selectedCountry?.isoCode,
        source: countrySearch === 'FRANCE' ? CitySourceFilter.INSEE : undefined,
      },
    })
  }, [countrySearch, citySearch, searchCity, selectedCountry?.isoCode])

  const handleDefaultBirthPlaceCode = useCallback(
    () => setValues({ ...values, birthPlaceCode: '99999' }),
    [setValues, values],
  )

  useDebounce(
    () => {
      handleSearchCountry()
    },
    400,
    [countrySearch],
  )

  useDebounce(
    () => {
      handleSearchCity()
    },
    400,
    [citySearch],
  )

  useEffect(() => {
    if (!selectedCountry && countrySearch === 'FRANCE') {
      handleSearchCity()
    }
  }, [countrySearch, handleSearchCity, selectedCountry])

  const clearCitySearchFilter = () => {
    setCitySearch('')
    setValues({ ...values, birthPlaceCode: '' })
  }

  const clearAllSearchFilter = () => {
    setCountrySearch('')
    setSelectedCountry(null)
    clearCitySearchFilter()
  }

  const handleDisplayFilter = () => {
    if (!searchBirthPlaceCodewithFilter) {
      clearCitySearchFilter()
      setCountrySearch('FRANCE')
    }
    setSearchBirthPlaceCodeWithFilter(!searchBirthPlaceCodewithFilter)
  }

  return (
    <>
      {searchBirthPlaceCodewithFilter && !inseeNumberValidator(values.inseeNumber) && (
        <>
          <CountriesSearch
            search={countrySearch}
            countries={countries.items}
            disabled={isDisabled}
            onSearch={(search) => {
              setCountrySearch(search)
              if (search === '') {
                setSelectedCountry(null)
                setCitySearch('')
                setValues({
                  ...values,
                  birthPlaceCode: '',
                })
              }
            }}
            onSelect={(item: Country | undefined) => {
              if (item) {
                setSelectedCountry(item.id)
                setCountrySearch(item.name)

                if (item.isoCode === 'FR') {
                  handleSearchCity()
                }

                if (item?.inseeCode) {
                  setValues({
                    ...values,
                    birthPlaceCode: item.inseeCode.toString(),
                  })
                }
              } else {
                clearAllSearchFilter()
              }
            }}
          />
          {(selectedCountry?.isoCode === 'FR' || countrySearch === 'FRANCE') && (
            <CitiesSearch
              search={citySearch}
              cities={cities.items}
              disabled={isDisabled}
              onSearch={(search) => {
                setCitySearch(search)
                if (search === '') {
                  setValues({
                    ...values,
                    birthPlaceCode: '',
                  })
                }
              }}
              onSelect={(item: City | undefined) => {
                if (item) {
                  if (item?.inseeCode) {
                    setCitySearch(item.name)
                    setValues({
                      ...values,
                      birthPlaceCode: item.inseeCode.toString(),
                    })
                  }
                } else {
                  clearCitySearchFilter()
                }
              }}
            />
          )}
        </>
      )}
      <DisabledINSITooltipWrapper display={isINSIValidated(values.identityStatus)}>
        <TooltipWrapper
          pointerDirection="top"
          content="Le code naissance est extrait du matricule INS"
          display={
            !isINSIValidated(values.identityStatus) && inseeNumberValidator(values.inseeNumber)
          }
          size="small"
        >
          <div>
            <FormikTextInput
              type="text"
              fieldName="birthPlaceCode"
              label="Code lieu de naissance (INSEE) *"
              disabled={isDisabled || searchBirthPlaceCodewithFilter}
              colorPreset="light"
              onChange={resetINSIStatus}
            />
            {!searchBirthPlaceCodewithFilter && (
              <div className={style.birthPlaceCodeHelpContainer}>
                <Icon icon="infoCircle" size="pico" />
                <div className={style.birthPlaceCodeTextContainer}>
                  Entrez
                  <RoundedButton
                    label="99999"
                    size="atom"
                    onClick={handleDefaultBirthPlaceCode}
                    disabled={isDisabled || isDefaultBirthPlaceCode}
                  />
                  si inconnu.
                </div>
              </div>
            )}
            {!isDisabled && (
              <button
                onClick={handleDisplayFilter}
                className={style.birthPlaceCodeSearchButton}
                type="button"
              >
                {searchBirthPlaceCodewithFilter
                  ? 'Saisir le code lieu de naissance manuellement'
                  : 'Effectuer une recherche avancée par pays et par ville'}
              </button>
            )}
          </div>
        </TooltipWrapper>
      </DisabledINSITooltipWrapper>
    </>
  )
}
